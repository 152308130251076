<template>
	<main class="vcc-main vcc-main__kb" v-if="showMenu" id="main">
		<h2 class="heading">База знаний</h2>
		<nav class="kb--menu">
			<button-card :to="{name: 'Rlas'}">
				<template #icon>
					<icon-law-papers/>
				</template>
				<template #text>Нормативно-правовые акты</template>
			</button-card>

			<button-card :to="{name: 'BusinessTypes'}">
				<template #icon>
					<icon-mud-cart/>
				</template>
				<template #text>Виды деятельности</template>
			</button-card>

			<button-card :to="{name:'Materials'}">
				<template #icon>
					<icon-plain-papers/>
				</template>
				<template #text>Все материалы</template>
			</button-card>
		</nav>
		<illustrated-form type="subs2"/>
		<vcc-faq :subheading="true"/>
	</main>
	<router-view v-else/>
</template>
<script>
import Illustrated from '@/components/forms/Illustrated.vue'
import Faq from '@/components/FaqSection.vue'

import ButtonCard from '@/components/common/ButtonCard.vue';

import IconLawPapers from '@/assets/svg/IconLawPapers.vue';
import IconMudCart from '@/assets/svg/IconMudCart.vue';
import IconPlainPapers from '@/assets/svg/IconPlainPapers.vue';

export default {
	components: {
		'illustrated-form': Illustrated,
		'vcc-faq': Faq,
		IconLawPapers,
		IconMudCart,
		IconPlainPapers,
		ButtonCard,
	},
	computed: {
		showMenu() {
			return this.$route.name == 'KnowledgeBase'
		}
	}
}
</script>
